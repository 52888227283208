import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"

import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'

// if there are multiple things being exported
// from utils (which there are) then we need
// to use the curry braces around the component
import { AboutHeader, BannerCenter } from "../utils"
import img from "../images/bcg/traditions-on-the-lake-lake-hartwell-restaurant.jpg"


const AboutPage = ({ data, location }) => {
  
  const images = data.images.edges.map(({ node }) => ({
    ...node.childImageSharp,
    // Use original name as caption.
    // The `originalName` is queried inside the `thumb` field,
    // but the `Gallery` component expects `caption` at the top level.
    caption: node.childImageSharp.thumb.originalName,
  }))
  
  return (
    <Layout>
      <SEO 
        title="About"
        img={img}
        url={location.href}
      />
      <AboutHeader img={img}>
        <BannerCenter
          title="Clemson Traditions"
          subtitle="New Southern American Cuisine."
        >
          <hr
            style={{ background: `tan`, height: `0.1em`, margin: `1.5rem 0` }}
          />
          <div id="about">
            <div className="row justify-content-center align-content-center py-3">
              <div className="bc-column col-sm-11 col-md-11 col-lg-11 align-self-center order-1 mb-5 mb-md-3">
                <p>
                  Located on Lake Hartwell and within Clemson's newest <a href="https://www.lakesidelodgeclemson.com/" style={{ color: `#fff`, textDecoration: `underline` }}>luxury hotel and resort</a>, <b>Traditions on the Lake</b> offers travelers and locals New Southern American cuisine with a classic twist using fresh locally-sourced ingredients. Legendary Clemson football coach Frank Howard believed in giving 110% to the game. We uphold those very same time-honored Clemson traditions with every dish we serve.
                </p>
              </div>
            </div>

            <div className="row justify-content-center align-content-start py-3">
              <div className="bc-column col-sm-11 col-md-11 col-lg-11 align-self-center order-1 mb-5 mb-md-3">
                <p>
                  That's why on our menu, you'll find free-range, grass-fed, antibiotic- and hormone-free meats, organically grown produce, and house-made pasta. Because some things are best done the old-fashioned way. The grand indoor fireplace and outdoor lakeside patio are two perfect places to enjoy a cocktail or order dessert with friends and family. The orange-onyx backlit bartop adds to the upscale ambiance and invites guests to choose from a wide selection of beer, wine, and spirits.
                </p>
              </div>
            </div>
          </div>

          
        </BannerCenter>
      </AboutHeader>
      <div id="gallery">
        <Gallery images={images} />
      </div>
    </Layout >
  )
}

export const query = graphql`
  query ImagesForGallery {
    images: allFile(
      filter: { relativeDirectory: { eq: "gallery" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
              originalName
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default AboutPage
